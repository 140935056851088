
import { Component, Emit, Prop, Vue } from "nuxt-property-decorator";

@Component({})
export default class Select extends Vue {
  @Prop({ default: "default" }) variant: string;
  @Prop() label: string;
  @Prop() id: string;
  @Prop() selected: string | number;
  @Prop({ default: "" }) supportMessage: string;
  @Prop({ default: false }) supportMessageVisibility: string;
  @Prop({ default: "" }) supportMessageIcon: string;
  @Prop({ default: "valid" }) validationState: string;
  @Prop({ default: true }) validate: string;
  @Prop({ required: true }) options: Array<any>;
  @Prop({ default: false }) disabled: boolean;

  _supportMessageVisibility;

  created() {
    this._supportMessageVisibility = this.supportMessageVisibility;
  }

  updated() {
    if (this.supportMessage != "" && this.validate) {
      this.validationState = "invalid";
    } else {
      this.validationState = "valid";
    }
  }

  @Emit()
  emitSelectedValue(e) {
    this._supportMessageVisibility = true;
    return e.target.value;
  }

  @Emit()
  emitSupportMessageClick() {
    return true;
  }
}
